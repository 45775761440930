import { isNil } from 'lodash';
import { ENVIRONMENTS, DataProvider } from '@harvestiq/constants';

// if 'default' shows up, something's wrong
export function getEnv(): string {
  return process.env['ENVIRONMENT'] ?? 'default';
}

export function getVersion(): string {
  return process.env['VERSION'] ?? '0.0.default';
}

export function getDDEnabled(): boolean {
  return getBool('DD_LOGS_ENABLED', false);
}

export function getDDLogLevel(): string {
  return (process.env['DD_LOG_LEVEL'] ?? 'debug').toUpperCase();
}

export function getLogLevel(): string {
  return (process.env['LOG_LEVEL'] ?? 'debug').toLowerCase();
}

export function getDatabaseURL(): string {
  if (isJestTest()) {
    return process.env['DATABASE_URL_TEST'] || '';
  }
  return process.env['DATABASE_URL'] || '';
}

export function isJestTest(): boolean {
  return process.env['NODE_ENV']?.toLowerCase() === ENVIRONMENTS.TEST;
}

export function isPrettyLogsEnabled(): boolean {
  return getBool('PRETTY_LOGS_ENABLED', false);
}

export function getPort(): number {
  return Number(process.env['PORT']) || 5000;
}

export function getWorkerPort(): number {
  return Number(process.env['WORKER_PORT']) || 9001;
}

export function getHost(): string {
  return process.env['HOST'] || '0.0.0.0';
}

export function getDatabaseUser(): string {
  return process.env['DB_USER'] || 'postgres';
}

export function getDatabasePassword(): string {
  return process.env['DB_PASSWORD'] || 'secret';
}

export function getDatabaseHost(): string {
  return process.env['DB_HOST'] || '0.0.0.0';
}

export function getDatabasePort(): number {
  return Number(process.env['DB_PORT']) || 5432;
}

export function getDatabaseName(): string {
  return process.env['DB_NAME'] || 'farmers-risk';
}

export function isDevelopment(): boolean {
  return (
    getEnv().toLowerCase() === ENVIRONMENTS.DEVELOPMENT ||
    getEnv().toLowerCase() === 'local'
  );
}

export function isTest(): boolean {
  return getEnv().toLowerCase() === ENVIRONMENTS.TEST;
}

export function isStaging(): boolean {
  return getEnv().toLowerCase() === ENVIRONMENTS.STAGING;
}

export function isProduction(): boolean {
  return getEnv().toLowerCase() === ENVIRONMENTS.PRODUCTION;
}

export function isCI(): boolean {
  return !!process.env['CI'];
}

export function getAuthZeroDomain(): string {
  return process.env['AUTH0_DOMAIN'] || 'farmersrisk-test.us.auth0.com';
}

export function getAuthZeroAudience(): string {
  return process.env['AUTH0_AUDIENCE'] || '';
}

export function getAuthZeroClientId(): string {
  return process.env['AUTH0_CLIENT_ID'] || '';
}

export function getAuthZeroClientSecret(): string {
  return process.env['AUTH0_CLIENT_SECRET'] ?? '';
}

export function getAuthZeroMgmtApiClientId(): string {
  return process.env['AUTH0_MGMT_API_CLIENT_ID'] ?? '';
}

export function getAuthZeroMgmtApiClientSecret(): string {
  return process.env['AUTH0_MGMT_API_CLIENT_SECRET'] ?? '';
}

// public api client id
export function getAuthZeroPublicApiClientId(): string {
  return process.env['AUTH0_PUBLIC_API_CLIENT_ID'] || '';
}
// client secret
export function getAuthZeroPublicApiClientSecret(): string {
  return process.env['AUTH0_PUBLIC_API_CLIENT_SECRET'] || '';
}

export function getAuthZeroPublicApiAudience(): string {
  return process.env['AUTH0_PUBLIC_API_AUDIENCE'] || '';
}

export function getFrontendHost(): string {
  return process.env['FRONTEND_HOST'] || 'http://localhost:3000';
}

export function getBackendHost(): string {
  return process.env['BACKEND_HOST'] || 'http://localhost:5000';
}

export function getSmtpHost(): string {
  return process.env['SMTP_HOST'] ?? '';
}

export function getSmtpPort(): number {
  return getNumber('SMTP_PORT');
}

export function getSmtpUsername(): string {
  return process.env['SMTP_USERNAME'] ?? '';
}

export function getSmtpPassword(): string {
  return process.env['SMTP_PASSWORD'] ?? '';
}

export function getMailSender(): string {
  return process.env['MAIL_SENDER_EMAIL'] || 'no-reply@harvestiq.com';
}

export function getInternalMailRecipient(): string {
  return (
    process.env['INTERNAL_EMAIL_RECIPIENT'] ||
    'notifications-test@harvestiq.com'
  );
}

export function emailEnabled(): boolean {
  return getBool('EMAIL_ENABLED', false);
}

export function getBarchartDelayedApiToken(): string {
  return process.env['BARCHART_API_TOKEN'] || '';
}

export function getBarchartRealtimeApiToken(): string {
  return process.env['BARCHART_REALTIME_API_TOKEN'] || '';
}

export function getTwilioAccountSid(): string {
  return process.env['TWILIO_ACCOUNT_SID'] || '';
}

export function getTwilioAuthToken(): string {
  return process.env['TWILIO_AUTH_TOKEN'] || '';
}

export function getSMSSenderNumber(): string {
  return process.env['SMS_SENDER_NUMBER'] || '';
}

export function smsEnabled(): boolean {
  return getBool('SMS_ENABLED', false);
}

export function getCronJobTriggerTime(): string {
  return process.env['CRON_JOB_TRIGGER_TIME_UTC'] || '0 14 * * *';
}

export function getPullTransactionTriggerTime(): string {
  return process.env['PULL_TRANSACTION_TRIGGER_TIME_UTC'] || '0 13 * * 2-6';
}

export function getWaitListTargetEmail(): string {
  return process.env['WAITLIST_EMAIL'] || 'info@harvestiq.com';
}

export function getSupportEmail(): string {
  return process.env['SUPPORT_EMAIL'] || 'support@harvestiq.com';
}

export function getS3BucketName(dataProvider: DataProvider): string {
  return (
    process.env[`AWS_DOCUMENT_BUCKET_${dataProvider}`] ||
    'staging1-farmers-documents-storage'
  );
}

export function getS3BucketForFiles(): string {
  return (
    process.env['AWS_INTEGRATIONS_BUCKET'] ||
    'integration-files.us-east-2.staging1'
  );
}

export function getS3BucketForFcmFiles(): string {
  return (
    process.env['AWS_INTEGRATIONS_BUCKET'] ||
    'integration-files.us-east-2.staging1'
  );
}

export function cronjobsEnabled(): boolean {
  return getBool('CRONJOBS_ENABLED', false);
}

export function getIntegrationHost(integrationName: string): string {
  return process.env[`INTEGRATIONS_${integrationName}_SFTP_HOST`] || '';
}

export function getIntegrationUserName(integrationName: string): string {
  return process.env[`INTEGRATIONS_${integrationName}_SFTP_USER_NAME`] || '';
}

export function getIntegrationPassword(integrationName: string): string {
  return process.env[`INTEGRATIONS_${integrationName}_SFTP_PASSWORD`] || '';
}

export function getIntegrationPort(integrationName: string): string {
  return process.env[`INTEGRATIONS_${integrationName}_SFTP_PORT`] || '22';
}

export function getStoneXEnv(): string {
  return process.env['INTEGRATIONS_STONEX_API_ENV'] || '';
}

export function getStoneXApiHost(): string {
  return process.env['INTEGRATIONS_STONEX_API_HOST'] || '';
}

export function getStoneXApiUsername(): string {
  return process.env['INTEGRATIONS_STONEX_API_USERNAME'] || '';
}

export function getStoneXApiPassword(): string {
  return process.env['INTEGRATIONS_STONEX_API_PASSWORD'] || '';
}

export function getStoneXSubKey(): string {
  return process.env['INTEGRATIONS_STONEX_API_SUB_KEY'] || '';
}

export function getStoneXEncryptionKeyAlias(): string {
  return process.env['INTEGRATIONS_STONEX_ENCRYPTION_KEY_ALIAS'] || '';
}

export function getStoneXOnlyUseServiceAccountLogin(): boolean {
  return getBool('INTEGRATIONS_STONEX_ONLY_USE_SERVICE_ACCOUNT_LOGIN', false);
}

export function getStoneXSaveRawApiRequests(): boolean {
  return getBool('INTEGRATIONS_STONEX_SAVE_RAW_API_REQUESTS', true);
}

export function getCQGDemoUsername(): string {
  return process.env['INTEGRATIONS_CQG_DEMO_USERNAME'] || '';
}

export function getCQGDemoPassword(): string {
  return process.env['INTEGRATIONS_CQG_DEMO_PASSWORD'] || '';
}

export function getAuthZeroAdminSRoleId(): string {
  return process.env['AUTH0_ADMINS_ROLE_ID'] || 'rol_FpM4yWtAI7Vw5GcD';
}

export function getAuthZeroTestAccountRoleId(): string {
  return process.env['AUTH0_TEST_ACCOUNT_ROLE_ID'] || 'rol_nxT1LN7MDy4f2AuP';
}

export function getDDApiKey(): string {
  return process.env['DD_API_KEY'] || '582be522eb94d9be1a1f87992fb20b78';
}

export function getLocalStackEnabled(): boolean {
  return getBool('LOCALSTACK_ENABLED', false);
}

export function getIntegrationFileDirectory(): string {
  return process.env['INTEGRATIONS_LOCAL_FILE_DIRECTORY'] ?? '';
}

export function getHybiscusApiKey(): string {
  return process.env['HYBISCUS_API_KEY'] || '';
}

export function getS3BucketForReports(): string {
  return process.env['AWS_REPORTS_BUCKET'] || 'reports-us-east-2-staging1';
}

export function getS3BucketForUploads(): string {
  return process.env['AWS_UPLOADS_BUCKET'] || 'uploads-us-east-2-staging1';
}

export function getGeocodioApiKey(): string {
  return process.env['GEOCODIO_API_KEY'] || '';
}

export function getS3BucketForFarmersRiskSFTP(): string {
  return process.env['INTEGRATIONS_FARMERS_RISK_SFTP_BUCKET'] || '';
}

export function getS3FolderForCunninghamQBOFiles(): string {
  return process.env['INTEGRATIONS_CUNNINGHAM_QBO_SFTP_FOLDER'] || '';
}

export function getWorkerUsername(): string {
  return process.env['CQG_WORKER_USERNAME'] ?? '';
}

export function getWorkerPassword(): string {
  return process.env['CQG_WORKER_PASSWORD'] ?? '';
}

export function getFileDownloadConcurrency(): number {
  return getNumber('FILE_DOWNLOAD_CONCURRENCY', 1);
}

export function getSGPortalHost(): string {
  return process.env['SGPORTAL_API_HOST'] ?? '';
}

export function getSGPortalClientId(): string {
  return process.env['SGPORTAL_CLIENT_ID'] ?? '';
}

export function getSGPortalClientSecret(): string {
  return process.env['SGPORTAL_CLIENT_SECRET'] ?? '';
}

export function getAppName(): string {
  return process.env['APP_NAME'] || 'HarvestIQ';
}

export function areMinisEnabled(): boolean {
  return getBool('MINIS_ENABLED', true);
}

export function getEnableEntitiesFeatureFlag(): boolean {
  return getBool('ENABLE_ENTITIES', false);
}

function getBool(key: string, defaultValue: boolean): boolean {
  if (key in process.env) {
    const val = process.env[key];

    if (isNil(val)) {
      return defaultValue;
    }

    if (val.toLowerCase() === 'true') {
      return true;
    }
    if (val.toLowerCase() === 'false') {
      return false;
    }
  }
  return defaultValue;
}

function getNumber(key: string, defaultValue: number | null = null): number {
  if (key in process.env) {
    const val = Number(process.env[key]);

    if (isNaN(val)) {
      return defaultValue ?? 0;
    }
    return val;
  }
  return defaultValue ?? 0;
}
